.about-us {
    font-weight: 400;

    &__container {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
        height: calc(100vh - 100px);
    }

    &__image-container {
        grid-column: 1 / 4;

        > img { 
            max-width: 100%;
            height: 100%;
        }
    }

    &__legend-container {
        display: flex;
        flex-direction: column;
        grid-column: 4 / 6;
        padding: 45px 25px;
        margin: 10px;
        margin-top: 50px;
        text-align: center;
        justify-content: flex-start;

    }

    &__title {
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 30px;
    }

    &__title::after {
        content: "";
        display: block;
        width: 225px;
        height: 1px;
        position: relative;
        left: 0;
        right: 15px;
        margin: auto;
        top: 25px;
        background-color: #f3a799;
    }

    &__description {
        margin-top: 50px;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        text-align: center;

        > p > span {
            font-style: italic;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .about-us  {
        &__container {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
        }

        &__image-container {
            grid-column: 1 / 5;
        }

        &__legend-container {
            margin-top: 0;
            grid-column: 5 / 8;
        }
    }
}


@media only screen and (max-width: 768px) {
    .about-us  {
        &__container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 100px);
        }

        &__legend-container {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 600px) {
    .about-us  {
        &__container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 100px);
        }

        &__image-container {    
            img {
                width: 100%;
                height: 100%;
            }
        }

        &__title {
            font-size: 25px;
        }

        &__description {
            font-size: 16px;
        }
    }
}