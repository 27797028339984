.reservations-container {
    display: flex;
    width: 100%;
    margin: 0;
    height: calc(100vh - 100px);

    font-weight: 400;
    font-family: 'Bitter', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    background-color: #fff4f2;
}

.reservations-img-panel {
    width: 33%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.reservations-open-table-panel {
    width: 34%;
    height: 100%;
    background-color: #fdf9f9;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ot-widget-container1:last-child {
    display: none;
}


@media only screen and (max-width: 600px) {

    .reservations-container {
        flex-direction: column;
    }

    .reservations-open-table-panel {
        padding: 15px 0;
        height: auto;
        width: 100%;
    }

    .reservations-img-panel {
        width: 100%;
        height: 25%;
        align-items: flex-start;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}