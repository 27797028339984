//#fff4f2
//#F8C1B7

* {
    margin: 0;
    padding: 0;
}

html {
    /* font-size: 10px; */
    /* 10px / 16px = 0.625 = 62.5% */
    /* Precentage of the user's browser font-size setting */
    font-size: 62.5%;
    overflow-x: hidden;
    font-family: 'Bitter', sans-serif;
    font-weight: 300;
}

body {
    color: #555;
    line-height: 1;
    font-weight: 400;
    overflow-x: hidden;
}