.menu {
    width: 100%;
    height: calc(100vh - 100px);
    overflow: auto;

    &__container {
        padding: 4em 30px;
    }

    &__container-language {
        display: flex;
        justify-content: end;
        width: 100%;
        height: auto;
    }

    &__container-options {
        display: flex;
        justify-content: space-around;
    }

    &__image-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > a > img {
            width: 300px;
            height: 400px;
            border: 1px solid gray;
            border-radius: 10px;
            cursor: pointer;
        }
    }
    
}

@media only screen and (max-width: 600px) {
    .menu {
        &__container-options {
            flex-direction: column;
        }

        &__container-language {
            justify-content: center;
            margin-bottom: 30px;
        }


        &__container-options-list {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .menu {
        &__container-options {
            flex-direction: column;
            align-items: center;
            gap: 25px;
        }
    }
}