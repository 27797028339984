.slide-show {
    &__slides {
        display: none;
    }

    &__slides--fade {
        animation-name: fade;
        animation-duration: 3.5s;
    }

    &__image {
        object-fit: cover;
        max-width: 100%;
        height: calc(100vh - 100px);
        width: 100%;
    }
}

@media only screen and (max-width: 750px) {
    .slide-show {
        &__image {
            width: 100%;
            height: calc(100vh - 100px);
        }
    }
}